<template>
    <div class="content app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span class="title">用户信息</span>
            </div>
            <div class="company-content">
                <el-descriptions>
                    <el-descriptions-item label="用户ID">{{
                        companyDetail.uid
                    }}</el-descriptions-item>
                    <el-descriptions-item label="注册时间">{{
                        Number(companyDetail.time + '000') | dayTimejs
                    }}</el-descriptions-item>
                    <el-descriptions-item label="姓名">{{
                        companyDetail.name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="手机号">{{
                        companyDetail.phoneNumber
                    }}</el-descriptions-item>
                    <el-descriptions-item label="城市"
                        >{{ companyDetail.province }} -
                        {{ companyDetail.country }}</el-descriptions-item
                    >
                    <!-- <el-descriptions-item label="企业标签">{{companyDetail.label}}</el-descriptions-item> -->
                    <!-- <el-descriptions-item>
            <el-tag
              style="margin-right:10px"
              size="small"
              v-for="(item, index) in companyDetail.tip"
              :key="index"
            >{{item}}</el-tag>
          </el-descriptions-item> -->
                </el-descriptions>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px">
            <div slot="header" class="clearfix">
                <el-row type="flex" justify="space-between" :gutter="24">
                    <el-col :span="16">
                        <span class="title">绑定的企业</span>
                    </el-col>
                    <!-- <el-col
            :span="6"
            style="text-align:right"
          >
            <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="user"
            >
            </el-input>
          </el-col> -->
                </el-row>
            </div>
            <div class="report-content">
                <el-table :data="companyData" border style="width: 100%">
                    <el-table-column prop="uscc" label="企业代码">
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column prop="customsCode" label="海关代码">
                    </el-table-column>
                    <el-table-column prop="cityCode" label="城市">
                        <template slot-scope="scope"
                            >{{ scope.row.cityCode }} -
                            {{ scope.row.cityName }}</template
                        >
                    </el-table-column>
                    <el-table-column prop="countyCode" label="县区">
                        <template slot-scope="scope"
                            >{{ scope.row.countyCode }} -
                            {{ scope.row.countyName }}</template
                        >
                    </el-table-column>
                    <el-table-column prop="time" label="时间">
                    </el-table-column>
                    <el-table-column prop="lmName" label="联系人">
                    </el-table-column>
                    <el-table-column prop="lmJob" label="职务">
                    </el-table-column>
                    <el-table-column prop="phoneNumber" label="手机">
                    </el-table-column>
                    <el-table-column prop="lmTel" label="电话">
                    </el-table-column>
                    <el-table-column prop="time" label="时间">
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <div @click="unbindClick(scope.row)">
                                {{
                                    scope.row.status === 0 ? '解除绑定' : '绑定'
                                }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <!-- <div>
          <Pagination
            v-bind:child-msg="companyPageparm"
            @callFather="companyCallFather"
          ></Pagination>
        </div> -->
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px">
            <div slot="header" class="clearfix">
                <el-row type="flex" justify="space-between" :gutter="24">
                    <el-col :span="16">
                        <span class="title">问卷填报记录</span>
                    </el-col>
                    <el-col :span="6" style="text-align: right">
                        <el-input
                          placeholder="请输入问卷名称"
                          prefix-icon="el-icon-search"
                          v-model="wjdoTitle"
                          clearable
                          @keyup.enter.native="onSubmit"
                        >
                        </el-input>
                    </el-col>
                </el-row>
            </div>
            <div class="report-content">
                <el-table :data="reportData" border style="width: 100%">
                    <el-table-column
                        prop="wenjuanDo.wjdoTitle"
                        label="问卷名称"
                    >
                    </el-table-column>
                    <el-table-column prop="uscc" label="企业代码">
                    </el-table-column>
                    <el-table-column prop="company.name" label="企业名称">
                    </el-table-column>
                    <el-table-column prop="time" label="填报时间">
                        <template slot-scope="scope" v-if="scope.row.time">{{
                            scope.row.time | dayjs
                        }}</template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div>
                    <Pagination
                        v-bind:child-msg="pageparm"
                        @callFather="callFather"
                    ></Pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    appleUserDetailApi,
    appleUserCompanyApi,
    appleUserSurveyApi,
    appleUserStatusApi,
} from '@/api/appletUser'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            user: '', //企业联系人搜索
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            wjdoTitle:'',//用户填报问卷搜索
            // companyPageparm: {
            //     currentPage: 1,
            //     pageSize: 10,
            //     total: 20,
            // },
            companyDetail: {},
            companyData: [],
            reportValue: 5,
            sixReportValue: 5,
            activityValue: '活跃',
            reportData: [],
        }
    },
    created() {
        if (this.$route.query.id) {
            this.appleUserDetailFun()
            this.appleUserCompanyFun()
            this.appleUserSurveyFun()
        }
    },
    methods: {
        // 获取详情
        async appleUserDetailFun() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await appleUserDetailApi(params)
            this.companyDetail = res
        },
        // 解除或者绑定用户
        async unbindClick(row) {
            console.log('row', row)
            var val = row.status === 0 ? -1 : 0
            var res = await appleUserStatusApi(row.id, val)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.appleUserCompanyFun()
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
        // 获取用户绑定企业
        async appleUserCompanyFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
            }
            var res = await appleUserCompanyApi(this.$route.query.id, params)
            this.companyData = res
            // this.companyPageparm.total = res.recordsTotal
        },
        // 获取用户参与过的问卷
        async appleUserSurveyFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                wjdoTitle: this.wjdoTitle
            }
            //  var p = Object.assign(params, this.formInline)
            var res = await appleUserSurveyApi(this.$route.query.id, params)
            this.reportData = res.data
            this.pageparm.total = res.recordsTotal
            
        },
        // 搜索用户填报问卷
        onSubmit(){
          this.appleUserSurveyFun()
        },
        // 绑定公司
        companyCallFather(parm) {
            this.appleUserCompanyFun(parm.currentPage, parm.pageSize)
        },
        // 分页插件事件
        callFather(parm) {
            this.appleUserSurveyFun(parm.currentPage, parm.pageSize)
        },
    },
}
</script>
<style scoped>
.content {
    /* width: 1200px; */
    /* margin: 0 auto; */
}
.card-box {
    height: 95px;
}
.report-title {
    color: #999999;
    font-size: 14px;
}
.report-value {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    margin-top: 10px;
}
.report-list {
    margin: 15px;
    font-size: 14px;
    font-weight: bold;
}
.title {
    font-size: 15px;
    font-weight: bold;
}
</style>
